<div
  class="button-group border-border-default flex flex-row flex-nowrap items-center justify-stretch gap-1 rounded-lg border p-1"
>
  @for (option of options; track $index) {
    @if (option.value === active) {
      <button class="emb-button emb-button-green w-full !flex-shrink" (click)="selectOption(option)">
        {{ option.label }}
      </button>
    } @else {
      <button class="emb-button emb-button-white w-full !flex-shrink" [disabled]="option.disabled" (click)="selectOption(option)">
        {{ option.label }}
      </button>
    }
  }
</div>
